// import Validator from '@/validators'
export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'aid',
      type: 'input',
      label: 'param.aid',
      otherOptions: {
        maxlength: 80,
        disabled: true
      }
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 16,
        lg: 16,
        md: 16,
        sm: 16,
        xs: 16
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ]
}
