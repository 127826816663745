// import Validator from '@/validators'
export const baseNewFormConfig = {
  formItems: [
    {
      field: 'aid',
      type: 'input',
      label: 'param.aid',
      otherOptions: {
        maxlength: 80
      }
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 16,
        lg: 16,
        md: 16,
        sm: 16,
        xs: 16
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    aid: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
