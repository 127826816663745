import { getParamsOptionsAsync } from '@/utils/common.js'
export const baseViewFormConfig = {
  formItems: [
    {
      field: 'aid',
      type: 'input',
      label: 'param.aid',
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'general.status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status'),
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        props: {
          multiple: true
        },
        disabled: true
      },
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 16,
        lg: 16,
        md: 16,
        sm: 16,
        xs: 16
      },
      otherOptions: {
        maxlength: 500,
        disabled: true
      }
    }
  ]
}
